import React, { useEffect } from "react";
import logo from "../../../assets/img/biglogo.webp";
import logo2 from "../../../assets/img/bigbig.webp";
import home from "../../../assets/img/home.svg";
import { Link } from "react-router-dom";

const Hero = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <section id="hero">
      <div className="container">
        <div className="hero--general">
          <div className="hero--general__group">
            <div className="hero-text">
              <img src={home} alt="img" className="hero--general__logo" />
              <h1 className="hero-text_title">
                Территория <br />
                новой жизни
              </h1>
              <div className="hero--general__group--content">
                <div />
                <p>
                  Baytik Lux Residence — это закрытый комплекс, где вы можете
                  наслаждаться комфортом и роскошью в безопасной и приватной
                  обстановке.
                </p>
              </div>
            </div>
            <Link to="/project">
              <button>Подробнее</button>
            </Link>
          </div>
          <img src={logo} alt="img" className="hero--general__img" />
          <img src={logo2} alt="img" className="hero--general__img2" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
