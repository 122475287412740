import React from "react";
import Hero from "./hero/hero";
import Admin from "./admin/admin";
import Infrast from "./infrast/infrast";
import Layouts from "./layouts/layouts";
import About from "./about/about";
import Partners from "./partners/partners";
import Contact from "./contact/contact";
import Footer from "../../components/footer/footer";
import Whatsapp from "../../components/Whatsapp";
import GoogleAnalytics from "../../seo/GoogleAnalytics ";

const Home = () => {
  return (
    <div>
      <GoogleAnalytics />
      <Hero />
      <Whatsapp />
      <Admin />
      <Infrast />
      <Layouts />
      <About />
      <Partners />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
