import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #E8ECEB;
  transform: ${({open}) => (open ? "translateY(0)" : "translateY(-100%)")};
  height: 80vh;
  width: 100%;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 576px) {
    padding: 50px 0 30px 0;
    width: 100%;
    height: 100vh;
    transform: ${({open}) => (open ? "translateY(0)" : "translateY(-190%)")};
    overflow-y: scroll;
  }


`;


const Menu = ({open, setOpen}) => {

    const clicked = () => {
        setOpen(false)
        window.scroll(0,0)
    }

    return (
        <StyledMenu open={open}>
            <div className="block-menu">
                 <a href="/#admin" onClick={clicked}>
                     Администрация
                 </a>
                <a href="/#layouts" onClick={clicked}>
                    Планировки
                </a>
                <a href="#" onClick={clicked}>
                    <Link to={'/gallery'}>
                        Галерея
                    </Link>
                </a>
            </div>
            <div className="block-menu">
                <a href="/#infrast" onClick={clicked}>
                    Инфраструктура
                </a>
                <a href="/#about" onClick={clicked}>
                    О компании
                </a>
                <a href="/#contact" onClick={clicked}>
                    Контакты
                </a>
            </div>
            <div className="menu-line"></div>
            <div className="block-nav">
                <a href="tel:+996 998 400 400" onClick={clicked}>+996 998 400 400</a>
            </div>
            <div className="block-icons">
                <a href="https://www.facebook.com/profile.php?id=100091750052689&mibextid=LQQJ4d" onClick={clicked}>
                    <svg width="38" height="39" viewBox="0 0 38 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="18.9816" cy="19.0617" r="18.9816" fill="#252525"/>
                        <path
                            d="M17.1364 16.9525H15.0273V19.0616H17.1364V25.3888H20.3V19.0616H22.2193L22.4091 16.9525H20.3V16.0741C20.3 15.57 20.4012 15.3707 20.8884 15.3707H22.4091V12.7344H19.874C17.9779 12.7344 17.1364 13.5696 17.1364 15.1682V16.9525Z"
                            fill="white"/>
                    </svg>
                </a>
                <a href="https://twitter.com/baytiklux_kg?s=11&t=pu50EsWDgUaoA152g_SiBA" onClick={clicked}>
                    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="19.9435" cy="19.0617" r="18.9816" fill="#252525"/>
                        <path
                            d="M26.2706 15.5344C25.8055 15.7411 25.3046 15.8803 24.7795 15.9435C25.3162 15.6219 25.7265 15.1136 25.9215 14.5073C25.4196 14.8047 24.8639 15.0208 24.2723 15.1368C23.7998 14.6317 23.1239 14.3164 22.3783 14.3164C20.7016 14.3164 19.4699 15.8803 19.8485 17.5043C17.692 17.3956 15.778 16.3622 14.4978 14.792C13.8176 15.9583 14.1456 17.4853 15.3014 18.2582C14.8764 18.2445 14.4767 18.1275 14.1266 17.9335C14.0981 19.1356 14.9607 20.2608 16.2093 20.5118C15.8444 20.6109 15.4437 20.6341 15.0367 20.5561C15.3667 21.5874 16.3274 22.3372 17.4621 22.3583C16.3685 23.2146 14.9945 23.5973 13.6162 23.4349C14.7657 24.1721 16.1292 24.6013 17.595 24.6013C22.4163 24.6013 25.1391 20.5297 24.9746 16.8779C25.4829 16.513 25.9226 16.0553 26.2706 15.5344Z"
                            fill="white"/>
                    </svg>
                </a>
                <a href="https://instagram.com/baytiklux.kg?igshid=YmMyMTA2M2Y=" onClick={clicked}>
                    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="19.9074" cy="19.0617" r="18.9816" fill="#252525"/>
                        <path
                            d="M19.9073 16.8283C18.6718 16.8283 17.6702 17.8281 17.6702 19.0616C17.6702 20.295 18.6718 21.2949 19.9073 21.2949C21.1428 21.2949 22.1443 20.295 22.1443 19.0616C22.1443 17.8281 21.1428 16.8283 19.9073 16.8283Z"
                            fill="white"/>

                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M16.307 12.933C18.6804 12.6682 21.1341 12.6682 23.5076 12.933C24.8143 13.0788 25.8683 14.1067 26.0216 15.4158C26.3054 17.838 26.3054 20.2851 26.0216 22.7074C25.8683 24.0165 24.8143 25.0443 23.5076 25.1901C21.1341 25.455 18.6804 25.455 16.307 25.1901C15.0003 25.0443 13.9463 24.0165 13.7929 22.7074C13.5091 20.2851 13.5091 17.838 13.7929 15.4158C13.9463 14.1067 15.0003 13.0788 16.307 12.933ZM23.3489 14.9385C22.9688 14.9385 22.6606 15.2462 22.6606 15.6257C22.6606 16.0052 22.9688 16.3129 23.3489 16.3129C23.7291 16.3129 24.0372 16.0052 24.0372 15.6257C24.0372 15.2462 23.7291 14.9385 23.3489 14.9385ZM16.6377 19.0616C16.6377 17.2589 18.1015 15.7975 19.9073 15.7975C21.713 15.7975 23.1768 17.2589 23.1768 19.0616C23.1768 20.8643 21.713 22.3256 19.9073 22.3256C18.1015 22.3256 16.6377 20.8643 16.6377 19.0616Z"
                              fill="white"/>
                    </svg>
                </a>
            </div>
        </StyledMenu>
    );
};

export default Menu;