import './style/style.scss'
import './App.css';
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import {Route, Routes} from "react-router-dom";
import Home from "./page/home/home";
import Gallery from "./page/gallery/gallery";
import DetailPage from "./page/detailPage250/detailPage";
import DetailPage300 from "./page/detailPage300/detailPage";
import DetailPage350 from "./page/detailPage350/detailPage";
import Project from "./page/project/project";


function App() {
    return (
        <>
            <Header/>
            <Routes>
                <Route path={'/'} element={<Home/>}/>
                <Route path={'/gallery'} element={<Gallery/>}/>
                <Route path={'/detail250'} element={<DetailPage/>}/>
                <Route path={'/detail300'} element={<DetailPage300/>}/>
                <Route path={'/detail350'} element={<DetailPage350/>}/>
                <Route path={'/project'} element={<Project/>}/>
            </Routes>
        </>
    );
}

export default App;
