import React from "react";
import "./style.scss";
import { RiWhatsappFill } from "react-icons/ri";
import { WHATSAPP_LINK } from "../../constants/admin";

const Whatsapp = () => {
  return (
    <a
      href={WHATSAPP_LINK}
      target="_blank"
      className="fixed bottom-[20px] right-[32px] rounded-[50%] z-[50]"
    >
      <div className="flex justify-center items-center w-[70px] h-[70px] bg-[#FFFFFF] rounded-[50%] shadow-[0px 4px 19.8px 0px #0000001F]">
        <RiWhatsappFill color="#39C769" fontSize="47.67px" />
      </div>
    </a>
  );
};

export default Whatsapp;
