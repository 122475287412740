import React, {useEffect} from 'react';
import imgOne from "../../../assets/img/admin-one.webp"
import {Link} from "react-router-dom";
import vector from "./../../../assets/img/vector.svg"

const Admin = () => {

    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
        <section id='admin'>
            <div className="container">
                <div className="admin">

                    <h1 className="admin--title">
                        <img src={vector} alt="img"/>
                    </h1>

                    <h2>АДМИНИСТРАЦИЯ</h2>

                    <div className="admin--text">
                        <div className="admin--text__line"></div>
                        <div className="admin--text__title">
                            <h5>Коттеджный городок Baytik Lux расположен в живописном селе байтик, в дали от городской
                                суеты.</h5>
                            <p>
                                Наш городок - это современное жилое пространство, которое имеет собственную
                                философию.
                                Вы будете
                                жить в особой атмосфере, забыв о том, что такое шум и суета и обретете по-настоящему
                                близких по
                                духу друзей.Высокое качество строительства будет гарантией долгосрочной эксплуатации
                                и
                                безопасности домов.
                            </p>
                        </div>
                    </div>

                    <img src={imgOne} alt="img"/>

                    <div className="admin--block">
                        <div className="admin--block__text">
                            <h1>Посмотреть больше <br/>фотографии проекта</h1>
                            <h3>Откройте галерею для просмотра больше фотографий с разных ракурсов, ночные виды проекта
                                и многое другое</h3>
                           <div> <Link to={'/gallery'}>
                                <button className="admin--block__text--btn">В галерею</button>
                            </Link>
                            </div>

                        </div>
                        <div style={{ width: '100%', maxWidth: '800px', margin: '30px 0' }}>
                             <div style={{ position: 'relative', paddingBottom: '54.25%', height: '0', overflow: 'hidden' }}>
                                  <iframe
                                         src="https://kinescope.io/embed/wQojTzRoGGvKYdh7ZKxfQv"
                                         allow="autoplay; fullscreen; picture-in-picture; encrypted-media; gyroscope; accelerometer; clipboard-write;"
                                         frameborder="0"
                                         allowfullscreen
                                         style={{ position: 'absolute', top: '0', right: '0', width: '100%', height: '100%' }}>  </iframe>
                             </div>
                            <style jsx>{`
                                @media (min-width: 800px) {
                                      iframe {
                                            width: 800px;
                                            height: 411px;
                                           }}`}</style>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Admin;
