import React from 'react';
import two from '../../../assets/img/250.webp'
import three from '../../../assets/img/300.webp'
import five from '../../../assets/img/350.webp'
import {Link} from "react-router-dom";
import vector from "./../../../assets/img/vector.svg"
const Layouts = () => {

    return (
        <section id='layouts'>
            <img src={vector} alt="img" className="layouts--bg"/>
            <div className="container">
                <div className="layouts--general">
                    <h1 className='layouts--general__title'>Планировки</h1>
                    <p className='layouts--general__desc'>ПЛАНИРОВКИ
                        На 3,5 гектарах земли, расположено 38 роскошных коттеджей площадью от 250 до 350 квадратных
                        метров каждый. Наш комплекс создан для семей, которые ищут комфортное и современное жилье с
                        развитой инфраструктурой.
                    </p>

                    <div className='layouts--general__group'>
                        <div className="block">

                            <div className='layouts--general__group--block'>
                                <img src={two} alt="" className="img"/>
                                <Link to='/detail250'>
                                    <button>Детальнее</button>
                                </Link>
                            </div>

                            <p>250 м²</p>
                        </div>
                        <div className="block">

                            <div className='layouts--general__group--block'>
                                <img src={three} alt="" className="img"/>
                                <Link to={'/detail300'}>
                                    <button>Детальнее</button>
                                </Link>
                            </div>

                            <p>300 м²</p>
                        </div>
                        <div className="block">

                            <div className='layouts--general__group--block'>
                                <img src={five} alt="" className="img"/>
                                <Link to={'/detail350'}>
                                    <button>Детальнее</button>
                                </Link>
                            </div>

                            <p>350 м²</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Layouts;
