import React, {useState} from 'react';
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io'
import {icons} from './helper'
import vector from "./../../../assets/img/vector2.svg"

const Infrast = () => {
    const [click, setClick] = useState(false);

    return (
        <section id="infrast">

            <img src={vector} alt="img" className="infrast--bg"/>
            <div className="container">
                <div className="infrast--general">
                    <h1 className='infrast--general__title'>Инфраструктура</h1>
                    <p className='infrast--general__desc'>Baytik Lux Residence предлагает множество возможностей для
                        развлечений и отдыха. Здесь вы сможете
                        в полной мере наслаждаться жизнью. Вот некоторые из наших мест отдыха:</p>

                    <div className="infrast--general__icons flex justify-center ">
                        <div className="infrast--general__icons--one flex mt-[100px] flex-wrap">
                            {icons.slice(0, click ? icons.length : 12).map((element, index) => (
                                <div
                                    className="infrast--general__icons--one__block my-10 mx-1 w-[320px] flex flex-col items-center">
                                    <div
                                        className="flex w-[62px] h-[62px] justify-center mx-auto items-center rounded-full border-[1px] border-[#DDCD9D]  border-solid ">
                                        {element.img}
                                    </div>
                                    <h1 className="font-[300] text-[17px] text-center mx-[5px] text-[#000000]">
                                        {element.title}
                                    </h1>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <div className="flex justify-center infrast--btn">
                            <button
                                className=" font-[400] text-[24px] flex items-center text-[#252525] mt-10"
                                onClick={() => setClick(!click)}>
                                {
                                    click ?
                                        <IoIosArrowUp className="text-[#B66A20] ml-2"/> :
                                        <IoIosArrowDown className="text-[#B66A20] ml-2"/>
                                }

                                {
                                    click ? "Развернуть" : "    Посмотреть больше"
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Infrast;