import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import pro from "./../../assets/img/pro.webp";
import Footer from "../../components/footer/footer";
import GoogleAnalytics from "../../seo/GoogleAnalytics ";
const Project = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <GoogleAnalytics />
      <section id="project">
        <div className="container">
          <div className="project">
            <Link to={"/"}>
              <h1>
                <svg
                  width="16"
                  height="9"
                  viewBox="0 0 16 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.646447 3.89645C0.451184 4.09171 0.451184 4.40829 0.646447 4.60355L3.82843 7.78553C4.02369 7.9808 4.34027 7.9808 4.53553 7.78553C4.7308 7.59027 4.7308 7.27369 4.53553 7.07843L1.70711 4.25L4.53553 1.42157C4.7308 1.22631 4.7308 0.909728 4.53553 0.714466C4.34027 0.519204 4.02369 0.519204 3.82843 0.714466L0.646447 3.89645ZM1 4.75H13.25V3.75H1V4.75ZM13.25 4.75C13.5463 4.75 13.8521 4.98035 13.9812 5.54831C14.1067 6.10075 14.0087 6.8356 13.584 7.47265L14.416 8.02735C14.9913 7.1644 15.1433 6.14925 14.9563 5.32669C14.7729 4.51965 14.2037 3.75 13.25 3.75V4.75Z"
                    fill="black"
                  />
                </svg>
                <span>На главную</span>
              </h1>
            </Link>

            <h2 className="project--title">О ПРОЕКТЕ</h2>

            <p>
              Добро пожаловать в Baytik Lux Residence. Это эксклюзивный комплекс
              на 3,5 гектарах земли, где расположено 38 роскошных коттеджей
              площадью от 250 до 350 квадратных метров каждый. Наш комплекс
              создан для семей, которые ищут комфортное и современное жилье с
              развитой инфраструктурой.
            </p>
            <p>
              Baytik Lux Residence — это закрытый комплекс, где вы можете
              наслаждаться комфортом и роскошью в безопасной и приватной
              обстановке. В нашем VIP городке есть все необходимые коммуникации
              — газовое отопление, артезианская вода и центральная канализация.
              Территория комплекса скрыта от посторонних глаз и находится под
              охраной. Здесь вы почувствуете себя в безопасности.
            </p>
            <p>
              Наш проект был разработан одним из лучших архитекторов города —
              Муратом Асеином. Он создал прекрасный архитектурный проект в
              современном стиле, который сочетает в себе функциональность и
              эстетику. Это качественный городок премиум класса международного
              уровня, где вы можете наслаждаться комфортом и роскошью, находясь
              в окружении красивой природы.
            </p>

            <img src={pro} alt="img" />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Project;
