import React, { useEffect } from "react";

const GoogleAnalytics = () => {
  useEffect(() => {
    // Создаем script элемент для асинхронной загрузки Google Analytics
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-BJC4WMKETY";
    script.async = true;
    document.head.appendChild(script);

    // Инициализируем dataLayer и gtag
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;

    gtag("js", new Date());
    gtag("config", "G-BJC4WMKETY");
  }, []);

  return null; // Этот компонент не рендерит ничего на странице
};

export default GoogleAnalytics;
