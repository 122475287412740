import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import one from "../../assets/img/img-one.webp"
import two from "../../assets/img/img-two.webp"
import three from "../../assets/img/img-three.webp"
import four from "../../assets/img/img-four.webp"
import five from "../../assets/img/img-five.webp"
import six from "../../assets/img/img-six.webp"
import seven from "../../assets/img/img-seven.webp"
import eight from "../../assets/img/img-eight.webp"
import I from "../../assets/img/img-I.webp"
import ten from "../../assets/img/img-ten.webp"
import eleven from "../../assets/img/img-eleven.webp"
import twelve from "../../assets/img/img-twelve.webp"
import thirteen from "../../assets/img/img-thirteen.webp"
import fourteen from "../../assets/img/img-fourteen.webp"
import fifteen from "../../assets/img/img-fifteen.webp"
import sixteen from "../../assets/img/img-sixteen.webp"
import seventeen from "../../assets/img/img-seventeen.webp"
import eighteen from "../../assets/img/img-eighteen.webp"
import nineteen from "../../assets/img/img-nineteen.webp"
import twoOne from "../../assets/img/250-one.webp"
import twoTwo from "../../assets/img/250-two.webp"
import twoThree from "../../assets/img/250-three.webp"
import twoFour from "../../assets/img/250-four.webp"
import threeOne from "../../assets/img/300-one.webp"
import threeTwo from "../../assets/img/300-two.webp"
import threeThree from "../../assets/img/300-three.webp"
import threeFour from "../../assets/img/300-four.webp"
import threeFive from "../../assets/img/300-five.webp"
import fourOne from "../../assets/img/350-one.webp"
import fourTwo from "../../assets/img/350-two.webp"
import fourThree from "../../assets/img/350-three.webp"
import fourFour from "../../assets/img/350-four.webp"
import fourFive from "../../assets/img/350-five.webp"
import GoogleAnalytics from '../../seo/GoogleAnalytics ';

const Gallery = () => {
    const [active, setActive] = useState("Общий вид городка");

    const [scroll, setScroll] = useState(0)

    window.addEventListener("scroll", () => setScroll(window.scrollY))
    useEffect(() => {
        window.scroll(0, 0)
    }, [])

    return (
      <section id="gallery">
        <GoogleAnalytics />
        <div className="container">
          <div className="gallery flex justify-between ">
            <div className="gallery--text">
              <div className="web">
                <Link to={"/"}>
                  <h2>
                    <svg
                      width="16"
                      height="9"
                      viewBox="0 0 16 9"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.646447 3.89645C0.451184 4.09171 0.451184 4.40829 0.646447 4.60355L3.82843 7.78553C4.02369 7.9808 4.34027 7.9808 4.53553 7.78553C4.7308 7.59027 4.7308 7.27369 4.53553 7.07843L1.70711 4.25L4.53553 1.42157C4.7308 1.22631 4.7308 0.909728 4.53553 0.714466C4.34027 0.519204 4.02369 0.519204 3.82843 0.714466L0.646447 3.89645ZM1 4.75H13.25V3.75H1V4.75ZM13.25 4.75C13.5463 4.75 13.8521 4.98035 13.9812 5.54831C14.1067 6.10075 14.0087 6.8356 13.584 7.47265L14.416 8.02735C14.9913 7.1644 15.1433 6.14925 14.9563 5.32669C14.7729 4.51965 14.2037 3.75 13.25 3.75V4.75Z"
                        fill="black"
                      />
                    </svg>
                    <span>На главную</span>
                  </h2>
                </Link>

                <h1>Baytik Lux Residence</h1>
                <a href="#generalForm">
                  <button
                    style={{
                      background: scroll < 1830 ? "#000" : "transparent",
                      color: scroll < 1830 ? "white" : "black",
                    }}
                    className="gallery--text__btn"
                    onClick={() => {
                      setActive("Общий вид городка");
                    }}
                  >
                    Общий вид городка
                  </button>
                </a>

                <h1>Администрация</h1>
                <div>
                  <a href="#adminDay">
                    <button
                      style={{
                        background:
                          scroll > 1830 && scroll < 4356
                            ? "#000"
                            : "transparent",
                        color:
                          scroll > 1830 && scroll < 4356 ? "white" : "black",
                      }}
                      className="gallery--text__btn"
                      onClick={() => {
                        setActive("Дневной вид");
                      }}
                    >
                      Дневной вид
                    </button>
                  </a>
                  <a href="#adminNight">
                    <button
                      style={{
                        background:
                          scroll > 4356 && scroll < 9952
                            ? "#000"
                            : "transparent",
                        color:
                          scroll > 4356 && scroll < 9952 ? "white" : "black",
                      }}
                      className="gallery--text__btn"
                      onClick={() => {
                        setActive("Ночной вид");
                      }}
                    >
                      Ночной вид
                    </button>
                  </a>
                </div>

                <h1>Коттеджи</h1>
                <div>
                  <a href="#twoHundredFifty">
                    <button
                      style={{
                        background:
                          scroll > 9952 && scroll < 12426
                            ? "#000"
                            : "transparent",
                        color:
                          scroll > 9952 && scroll < 12426 ? "white" : "black",
                      }}
                      className="gallery--text__btn"
                      onClick={() => {
                        setActive("250");
                      }}
                    >
                      250
                    </button>
                  </a>
                  <a href="#threeHundred">
                    <button
                      style={{
                        background:
                          scroll > 12426 && scroll < 15456
                            ? "#000"
                            : "transparent",
                        color:
                          scroll > 12426 && scroll < 15456 ? "white" : "black",
                      }}
                      className="gallery--text__btn"
                      onClick={() => {
                        setActive("300");
                      }}
                    >
                      300
                    </button>
                  </a>
                  <a href="#threeHundredFifty">
                    <button
                      style={{
                        background: scroll > 15456 ? "#000" : "transparent",
                        color: scroll > 15456 ? "white" : "black",
                      }}
                      className="gallery--text__btn"
                      onClick={() => {
                        setActive("350");
                      }}
                    >
                      350
                    </button>
                  </a>
                </div>
              </div>

              <div className="media">
                <button className="media--btn">
                  <Link to={"/"}>На главную</Link>
                </button>

                <div className="page">
                  <div className="dropup">
                    <button className="dropup__btn primary-btn">Галерея</button>
                    <ul className="dropup__list">
                      <li className="dropup__item">
                        <a className="dropup__link" href="#generalForm">
                          Общий вид
                          <br />
                        </a>
                      </li>
                      <li className="dropup__item">
                        <a className="dropup__link" href="#adminDay">
                          Администратор
                          <br />
                          (дневной вид)
                        </a>
                      </li>
                      <li className="dropup__item">
                        <a className="dropup__link" href="#adminNight">
                          Администратор
                          <br /> (ночной вид)
                        </a>
                      </li>
                      <li className="dropup__item">
                        <a className="dropup__link" href="#twoHundredFifty">
                          250
                        </a>
                      </li>
                      <li className="dropup__item">
                        <a className="dropup__link" href="#threeHundred">
                          300
                        </a>
                      </li>
                      <li className="dropup__item">
                        <a className="dropup__link" href="#threeHundredFifty">
                          350
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="gallery--img">
              <h3 id="generalForm">Общий вид</h3>
              <img src={one} alt="img" />
              <img src={two} alt="img" />
              <img src={three} alt="img" />

              <h2 id="adminDay">Администрация (дневной вид)</h2>
              <img src={four} alt="img" />
              <img src={five} alt="img" />
              <img src={six} alt="img" />
              <img src={seven} alt="img" />

              <h2 id="adminNight">Администрация (ночной вид)</h2>
              <img src={eight} alt="img" />
              <img src={I} alt="img" />
              <img src={ten} alt="img" />
              <img src={eleven} alt="img" />
              <img src={twelve} alt="img" />
              <img src={thirteen} alt="img" />
              <img src={fourteen} alt="img" />

              <div className="flex">
                <div>
                  <img src={fifteen} alt="img" />
                  <img src={seventeen} alt="img" />
                  <img src={eighteen} alt="img" />
                </div>
                <div>
                  <img src={sixteen} alt="img" />
                  <img src={nineteen} alt="img" />
                </div>
              </div>

              <h2 id="twoHundredFifty">250 м²</h2>
              <img src={twoOne} alt="img" />
              <img src={twoTwo} alt="img" />
              <img src={twoThree} alt="img" />
              <img src={twoFour} alt="img" />

              <h2 id="threeHundred">300 м²</h2>
              <img src={threeOne} alt="img" />
              <img src={threeTwo} alt="img" />
              <img src={threeThree} alt="img" />
              <img src={threeFour} alt="img" />
              <img src={threeFive} alt="img" />

              <h2 id="threeHundredFifty">350 м²</h2>
              <img src={fourOne} alt="img" />
              <img src={fourTwo} alt="img" />
              <img src={fourThree} alt="img" />
              <div className="flex">
                <img src={fourFour} alt="img" className="w-[50%]" />
                <img src={fourFive} alt="img" className="w-[50%]" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default Gallery;
