import React from 'react';
// import footerLogo from './../../assets/img/footerLogo.svg'
import {FaFacebookF} from 'react-icons/fa';
import {BsTwitter} from "react-icons/bs";
import {AiFillInstagram} from "react-icons/ai";


const Footer = () => {
    return (
        <footer id='footer'>
            <div className="container">
                <div className="footer--general">
                    {/*<img src={footerLogo} alt=""/>*/}
                    <div className='footer--general__route'>
                        <a href="#">О компании</a>
                        <a href="#">Коттеджи</a>
                        <a href="#">Партнеры</a>
                        <a href="#">Контакты</a>
                    </div>
                    <div className='footer--general__icons'>
                        <div><a href="https://www.facebook.com/profile.php?id=100091750052689&mibextid=LQQJ4d"
                                target="_blank"><FaFacebookF/></a></div>
                        <div><a href="https://twitter.com/baytiklux_kg?s=11&t=pu50EsWDgUaoA152g_SiBA"
                                target="_blank"><BsTwitter/></a></div>
                        <div><a href="https://instagram.com/baytiklux.kg?igshid=YmMyMTA2M2Y="
                                target="_blank"><AiFillInstagram/></a></div>
                    </div>
                    <div className='footer--general__line'/>

                    <div className="footer--general__footer">
                        <p>Baytik Lux 2024. Все права защищены.</p>
                        <a href="https://motion.kg/" target="_blank"><p>Сайт разработал Motion Web IT Studio</p></a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
