import React from 'react';
import pa from '../../../assets/img/sch.JPG'
import na from '../../../assets/img/na.svg'
import bmw from '../../../assets/img/bmw.svg'
import vector from './../../../assets/img/vector3.svg'
const Partners = () => {
    return (
        <section id='partners'>
            <img src={vector} alt="img" className="partners--bg"/>
            <div className="container">
                <div className="partners--general">
                    <h1 className='partners--general__title'>Наши партнеры:</h1>
                    <div className='partners--general__block flex justify-evenly items-center px-10 pt-20'>
                        <div>
                            <img src={pa} alt=""/>
                        </div>
                        <div>
                            <img src={bmw} alt="" className='w-[130px]'/>
                        </div>
                        <div>
                            <img src={na} alt=""/>
                        </div>
                      
                        {/*<div>*/}
                            {/*<img src={di} alt=""/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Partners;